@import '../../style/constantes.less';

.login-page {
  &-container {
    // height: 633px;
    padding: 40px;
    width: 690px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc((90vh - 633px) / 2);
    background-color: @substrate-color;
    border-radius: 20px;
    overflow: hidden;

    .company-row {
      display: flex;
      justify-content: center;
    }

    .company-title {
      font-size: 14px;
      font-weight: 700;
    }

    .company-logo {
      display: flex;
      font-weight: 700;
      font-size: 14px;
    }
  }
  &-innerContainer {
    width: 454px;
    // height: 513px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &-header {
    display: block;
    margin: 10px auto 0;
  }
  &-infoBox {
    display: flex;
    margin-top: -7px;
    justify-content: space-between;
  }
}

@primary-color: #1DA57A;